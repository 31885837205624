import React from "react"
import {Alert} from "react-bootstrap"

const CovidMessage = ({siteTitle}) => (
    <>
        <Alert variant="warning" className="mb-0">
            <p className="text-center mb-0">
                <strong>Covid-19 Update: </strong> We will continue attending appointments at your home in a safe and contact-free manner.
            </p>
        </Alert>
    </>
)

export default CovidMessage
