import React from "react"
import { Component } from "react";

class SingleArticle extends Component{

    articleTitle = this.props.articleTitle;
    articleContent = this.props.articleContent;
    articleImg = this.props.articleImg;
    articleAuthor = this.props.articleAuthor;
    articleDate = this.props.articleDate;

    contentRef = React.createRef();

    componentDidMount(){
        let headers = this.contentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');

        for (let i = 0; i < headers.length; i++){
            headers[i].classList.toggle('single_article_subtitle');
        }
    }

    DateConverter(date){
        // date in the format d-m-y from WP
        let components = date.split('/');

        let now = new Date(components[2],components[1],components[0]);
        let day = now.getDate();
        let mounth = now.getMonth();
        let year = now.getFullYear();

        day = day.toString().length === 1 ? "0" + day : day;
        mounth = mounth.toString().length === 1 ? "0" + mounth : mounth;

        let result = day + "-" + mounth + "-" + year;

        return result;
    }

    AuthorCreated(){
        return this.articleAuthor ? this.articleAuthor : "Lu";
    }

    render(){
        return (
            <div className="blog-single-aticle">
                <div>
                    <h1 className="home_title text-primary-dark font-weight-800 letter-spacing-n2 mb-4 text-center">{this.articleTitle}</h1>
                </div>

                <div className="blog-article-img-wrapper">
                    <figure className="blog-figure">
                        <img className="blog-article-img" src={this.articleImg?.mediaItemUrl ?? ""} alt=""></img>
                        <figcaption>
                            <p className="text-muted small">Authored on <span>{"on " + this.DateConverter(this.articleDate)}</span> by <span>{ this.AuthorCreated() }</span></p>
                        </figcaption>
                    </figure>
                </div>

                <div className="blog-article-text col-8 offset-2" dangerouslySetInnerHTML={{ __html: this.articleContent}} ref={this.contentRef}>
                </div>

                <div className="blog-article-back-link-wrapper col-8 offset-2">
                    <a href="/blog">Back to blog</a>
                </div>

            </div>
        )
    }
}

export default SingleArticle
