import React from "react"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import { Container } from "react-bootstrap"
import Layout from "../components/layout";
import SingleArticle from "../components/SingleArticle";

const ArticlePage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "blog page"}}>

            <SEO title={data?.post?.metaForPage?.title ?? "Article page"} description={data?.post?.metaForPage?.description} />
            <CovidMessage></CovidMessage>
            <Container className="mt-4 mb-4 blog-page">
                <SingleArticle articleAuthor={data?.post?.blogPostInfo?.author} articleDate={data?.post?.blogPostInfo?.date} articleTitle={data?.post?.title} articleContent={data?.post.content} articleImg={data?.post?.blogPostInfo?.img}></SingleArticle>
            </Container>

        </Layout>
    )
}

export const data = graphql`
    query ($id: String) {
        post: wpPost(id: {eq: $id}) {
            title
            content
            blogPostInfo {
                author
                date
                img {
                    mediaItemUrl
                    altText
                }
            }
            metaForPage {
                description
                title
            }
        }
    }
`

export default ArticlePage
